import { Box, Button, CircularProgress, TablePagination, TextField, Typography } from "@mui/material"
import { Container } from "@mui/system"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { fetchCases } from "../../api/casesApi"
import UserContext from "../../contexts/UserContext"
import useApi from "../../hooks/useApi"
import Header from "../subcomponents/Header"
import Searchbar from "../subcomponents/SearchBar"
import TableRow from "../subcomponents/TableRows"
import CaseModal from "./caseModal/CaseModal"
import DataViewModal from "./DataViewModal"

const HomePage = () => {
    const user = useContext(UserContext)
    const [model, setModel] = useState({ open: false, data: {}, step: 0 })
    const [dataviewModel, setDataviewModel] = useState({ open: false, data: {} })

    const [loading, setLoading] = useState(false)
    const callApi = useApi()
    const [params, setParams] = useState({
        pageNo: 1,
        pageSize: 15,
        search: ''

    })
    const [data, setData] = useState({})

    const fetchData = async () => {
        setLoading(true)
        await callApi(async () => { return await fetchCases(params) }, (response) => { setLoading(false); setData(response) }, (err) => { setLoading(false); alert(err) })
    }


    const openModel = (data = {}, step = 1) => {

        setModel({ ...model, open: true, data, step })
    }
    const closeModel = () => {
        setModel({ ...model, open: false, data: {}, step: 0 })
    }


    const openViewModel = (data = {}, step = 1) => {

        setDataviewModel({ ...model, open: true, data, step })
    }
    const closeViewModel = () => {
        setDataviewModel({ ...model, open: false, data: {}, step: 0 })
    }

    useEffect(() => {
        fetchData()
    }, [params])


    return (
        <>
            <Header />
            <DataViewModal key={dataviewModel.open} open={dataviewModel.open} data={dataviewModel.data} handleClose={closeViewModel} />

            <CaseModal key={model.open} open={model.open} data={model.data} initalStep={model.step} onComplete={async () => fetchData()} handleClose={closeModel} />

            <Container maxWidth="lg">
                <Box mt={4} mb={3} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    {user && user.user && user.user.role === 'admin' && <Link  style={{textDecoration:"unset"}} to={"/user"}><Button variant="contained" sx={{mr:2}} onClick={openModel}>Manage Users</Button></Link>}

                    <Button variant="contained" onClick={openModel}>Create Case</Button>
                </Box>
                <Searchbar onChange={(e) => { setParams({ ...params, search: e.target.value }) }} fullWidth={true} placeholder="Search Cases by Patient Name & Case Id" />
                {loading && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} mt={3}>
                    <CircularProgress />
                </Box>}
                {!loading && data && data.data && Array.isArray(data.data) && <>
                    <TablePagination
                        component="div"
                        count={data.count ?? 0}
                        page={params.pageNo - 1}
                        rowsPerPageOptions={[15]}
                        onPageChange={(e, newPage) => { setParams({ ...params, pageNo: newPage }) }}
                        rowsPerPage={15}
                        onRowsPerPageChange={() => { }}
                    />
                    {
                        data.data.length == 0 && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} mt={3}>
                            <Typography variant="h5" >No data found</Typography>
                        </Box>
                    }
                    {
                        data.data.map((item) => <TableRow onEditButtonClick={openModel} onViewButtonClick={openViewModel} key={item.caseId} item={item} />)
                    }

                </>}


            </Container>
        </>
    )

}
export default HomePage