import logo from './logo.svg';
import './App.css';
import Login from './components/pages/Login';
import { useContext } from 'react';
import UserContext from './contexts/UserContext';
import HomePage from './components/pages/HomePage';
import './fonts/stylesheet.css'
import UserPage from './components/pages/user/UserPage';
import { Route, Routes } from 'react-router-dom';

function App() {
  const user = useContext(UserContext)
  return (
    <>
      {user.isLoggedIn ?

        <Routes>
          <Route  path='' element={<HomePage />}/>
          {user.user.role==='admin' &&<Route  path='user' element={<UserPage/>}/>}
        
        </Routes>
        :
        <Login />}
    </>


  );
}

export default App;
