import * as React from 'react';
import Dialog from '@mui/material/Dialog';

import UpdateCaseModal from './UpdateCaseModal';
import CreateCaseModal from './CreateCaseModal';
import { Grow, Slide } from '@mui/material';

const CaseModal = ({open, initalStep, data, onComplete, handleClose}) => {

    const [step, setStep] = React.useState(initalStep)
    
    const [id, setId] = React.useState(data.updateId)

    return <Dialog maxWidth="md" closeAfterTransition TransitionComponent={Slide} fullWidth open={open} onClose={handleClose}>
        {step == 1 && <CreateCaseModal handleClose={handleClose} onSubmit={async (updateId) => { await onComplete(); setId(updateId); setStep(2); }} />}
        {step == 2 && <UpdateCaseModal  handleClose={handleClose} updateId={id} onSubmit={async() => {await onComplete();  handleClose() }} />}
    </Dialog>
}
export default CaseModal