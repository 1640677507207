import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Input, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import useApi from '../../../hooks/useApi';
import { createCases } from '../../../api/casesApi';
import { createUser } from '../../../api/user.api';

const CreateUserModal = ({ onSubmit, handleClose }) => {
  const [loading, setLoadng] = React.useState(false)
  const callApi = useApi()
  const [state, setState] = React.useState({
    name: '',
    email: '',
    password: '',
    role: '',
    prefix: ''
  })
  const formSubmit = async () => {
    setLoadng(true);
    callApi(async () => createUser({ ...state }), (response) => { setLoadng(false); onSubmit() }, (errMsg) => {
      setLoadng(false);
      setState({ ...state, err: errMsg })
    })
  }
  return <>
    <DialogTitle>
      Create User
      <Typography color={'error'}>{state.err}</Typography>
    </DialogTitle>

    <DialogContent>

      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Name *"
        type="name"
        fullWidth
        value={state.name}
        onChange={(e) => setState({ ...state, name: e.target.value })}

      />

      <TextField
        autoFocus
        margin="dense"
        id="email"
        label="email *"
        type="email"
        fullWidth
        value={state.email}
        onChange={(e) => setState({ ...state, email: e.target.value })}

      />

      <TextField
        autoFocus
        margin="dense"
        id="password"
        label="password *"
        type="password"
        fullWidth
        value={state.password}
        onChange={(e) => setState({ ...state, password: e.target.value })}

      />

      <TextField
        autoFocus
        margin="dense"
        id="prefix"
        label="prefix *"
        type="prefix"
        fullWidth
        value={state.prefix}
        onChange={(e) => setState({ ...state, prefix: e.target.value })}

      />

      <FormControl margin="dense" sx={{ pl: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Role</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={state.role}
          onChange={(e) => { setState({ ...state, role: e.target.value }) }}
        >
          <FormControlLabel value="admin" control={<Radio />} label="Admin" />
          <FormControlLabel value="general" control={<Radio />} label="General" />


        </RadioGroup>
      </FormControl>



    </DialogContent>
    <DialogActions>
      <Button variant='contained' color='error' onClick={handleClose}>Cancle</Button>
      <Button type='submit' variant='contained' onClick={formSubmit} disabled={loading}> {loading ? <CircularProgress size={'25px'} /> : ' Create'}</Button>
    </DialogActions>

  </>
}
export default CreateUserModal