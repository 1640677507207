import { styled, TextField } from "@mui/material";

 const Searchbar = styled(TextField)(({ theme, active }) => ({
    borderRadius: "1000px !important",
background:"white",
    'fieldset': {
        borderRadius: "1000px"
    },
   
}));
export default Searchbar