import * as React from 'react';
import Dialog from '@mui/material/Dialog';

import { Grow, Slide } from '@mui/material';
import CreateUserModal from './CreateUserModal';

const UserModal = ({open, initalStep, data, onComplete, handleClose}) => {
    

    return <Dialog maxWidth="md" closeAfterTransition TransitionComponent={Slide} fullWidth open={open} onClose={handleClose}>
        {<CreateUserModal handleClose={handleClose} onSubmit={async () => { await onComplete();handleClose() }} />}
        
    </Dialog>
}
export default UserModal