import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Input, MenuItem, Radio, RadioGroup, Select, Switch, Typography } from '@mui/material';
import useApi from '../../../hooks/useApi';
import { createCases, updateCases } from '../../../api/casesApi';
const DbDropDown = ({ value, onChange, label, ...rest }) => {
  return <Box sx={{ width: "100%", alignItems: 'center', display: "flex", mt: 2 }}>
    <Typography sx={{ display: 'flex', flex: 1 }} variant='subtitle1'>{label}</Typography>
    <Select

      labelId="demo-simple-select-label"
      id="demo-simple-select"
      {...rest}
      value={value}
      onChange={onChange}
      sx={{ display: 'flex', flex: 1 }}

    >
      <MenuItem value={'--'} defaultValue>--dB</MenuItem>
      <MenuItem value={5} >5dB</MenuItem>
      <MenuItem value={10}>10dB</MenuItem>
      <MenuItem value={15}>15dB</MenuItem>
      <MenuItem value={20}>20dB</MenuItem>
      <MenuItem value={25}>25dB</MenuItem>
      <MenuItem value={30}>30dB</MenuItem>
      <MenuItem value={35}>35dB</MenuItem>

      <MenuItem value={40}>40dB</MenuItem>
      <MenuItem value={45}>45dB</MenuItem>
      <MenuItem value={50}>50dB</MenuItem>
      <MenuItem value={55}>55dB</MenuItem>

      <MenuItem value={60}>60dB</MenuItem>
      <MenuItem value={65}>65dB</MenuItem>

      <MenuItem value={70}>70dB</MenuItem>
      <MenuItem value={75}>75dB</MenuItem>
      <MenuItem value={80}>80dB</MenuItem>
      <MenuItem value={85}>85dB</MenuItem>
      <MenuItem value={90}>90dB</MenuItem>
      <MenuItem value={95}>95dB</MenuItem>
      <MenuItem value={100}>100dB</MenuItem>
    </Select>
  </Box>
}
const UpdateCaseModal = ({ onSubmit, handleClose, updateId }) => {
  const [loading, setLoadng] = React.useState(false)
  const callApi = useApi()
  const [state, setState] = React.useState({
    updateId,
    l500: '--',
    l1k: '--',
    l2k: '--',
    l4k: '--',
    l8k: '--',
    r500: '--',
    r1k: '--',
    r2k: '--',
    r4k: '--',
    r8k: '--',
    ableToHear: false,
    ableToUnderstand: false,
    remarks: '',
    conclusion: 'nutral',
    err: ''
  })

  const formSubmit = async () => {


    const validateData = { ...state }
    delete validateData['remarks']
    delete validateData['ableToHear']
    delete validateData['ableToUnderstand']
    delete validateData['err']
    let err = null
    Object.keys(validateData).forEach((item) => {
      if (!state[item] || state[item] == '--') {
        err = item.slice(0, 1) == 'r' ? 'Enter Right ear ' + item.slice(1) + "Hz value" : 'Enter Left ear ' + item.slice(1) + "Hz value"
      }

    })

    if (err) {
      setState({ ...state, err })
      return
    }
    setLoadng(true)
    callApi(async () => updateCases({ ...state }), (response) => { setLoadng(false); onSubmit(response._id) }, (errMsg) => {
      setLoadng(false);
      setState({ ...state, err: errMsg })
    })
  }
  return <>
    <DialogTitle>
      Patient Profile
      <Typography color={'error'}>{state.err}</Typography>
    </DialogTitle>

    <DialogContent>

      <Box sx={{ display: "flex", flex: 1 }}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column", p: 2, background: "whitesmoke" }} mr={2}>
          <Typography variant='subtitle1' fontWeight={600}>Left Ear</Typography>
          <DbDropDown
            autoFocus
            margin="dense"
            id="l500"
            label="500Hz"
            type="l500"
            fullWidth
            value={state.l500}
            onChange={(e) => setState({ ...state, l500: e.target.value })}

          />

          <DbDropDown
            autoFocus
            margin="dense"
            id="l1k"
            label="1kHz"
            type="l1k"
            fullWidth
            value={state.l1k}
            onChange={(e) => setState({ ...state, l1k: e.target.value })}

          />
          <DbDropDown
            autoFocus
            margin="dense"
            id="l2k"
            label="2kHz"
            type="l2k"
            fullWidth
            value={state.l2k}
            onChange={(e) => setState({ ...state, l2k: e.target.value })}

          />

          <DbDropDown
            autoFocus
            margin="dense"
            id="l4k"
            label="4kHz"
            type="l4k"
            fullWidth
            value={state.l4k}
            onChange={(e) => setState({ ...state, l4k: e.target.value })}

          />
          <DbDropDown
            autoFocus
            margin="dense"
            id="l8k"
            label="8kHz"
            type="l8k"
            fullWidth
            value={state.l8k}
            onChange={(e) => setState({ ...state, l8k: e.target.value })}

          />
        </Box>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column", p: 2, background: "whitesmoke" }}>
          <Typography variant='subtitle1' fontWeight={600}>Right Ear</Typography>
          <DbDropDown
            autoFocus
            margin="dense"
            id="r500"
            label="500Hz"
            type="r500"
            fullWidth
            value={state.r500}
            onChange={(e) => setState({ ...state, r500: e.target.value })}

          />
          <DbDropDown
            autoFocus
            margin="dense"
            id="r1k"
            label="1kHz"
            type="r1k"
            fullWidth
            value={state.r1k}
            onChange={(e) => setState({ ...state, r1k: e.target.value })}

          />
          <DbDropDown
            autoFocus
            margin="dense"
            id="r2k"
            label="2kHz"
            type="r2k"
            fullWidth
            value={state.r2k}
            onChange={(e) => setState({ ...state, r2k: e.target.value })}

          />
          <DbDropDown
            autoFocus
            margin="dense"
            id="r4k"
            label="4kHz"
            type="r4k"
            fullWidth
            value={state.r4k}
            onChange={(e) => setState({ ...state, r4k: e.target.value })}

          />
          <DbDropDown
            autoFocus
            margin="dense"
            id="r8k"
            label="8kHz"
            type="r8k"
            fullWidth
            value={state.r8k}
            onChange={(e) => setState({ ...state, r8k: e.target.value })}

          />
        </Box>
      </Box>





      <Box>
        <FormControl margin="dense" sx={{ pl: 1 }}>
          <FormLabel id="demo-controlled-radio-buttons-group">Able to Hear Sound?</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={state.ableToHear}
            onChange={(e) => { setState({ ...state, ableToHear: e.target.value === 'false' ? false : true }) }}
          >

            <FormControlLabel value={true} control={<Radio />} label={"Yes"} />

            <FormControlLabel value={false} control={<Radio />} label="No" />

          </RadioGroup>

          {/* <Switch checked={state.ableToHear} onChange={(e) => setState({ ...state, ableToHear: e.target.checked })} /> */}
        </FormControl>
      </Box>
      <Box>
        <FormControl margin="dense" sx={{ pl: 1 }}>
          <FormLabel id="demo-controlled-radio-buttons-group">Able to Understand Speech?</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={state.ableToUnderstand}
            onChange={(e) => { setState({ ...state, ableToUnderstand: e.target.value === 'false' ? false : true }) }}
          >

            <FormControlLabel value={true} control={<Radio />} label={"Yes"} />

            <FormControlLabel value={false} control={<Radio />} label="No" />

          </RadioGroup>
          {/* <Switch checked={state.ableToUnderstand} onChange={(e) => setState({ ...state, ableToUnderstand: e.target.checked })} /> */}
        </FormControl>
      </Box>
      <TextField
        autoFocus
        margin="dense"
        id="remarks"
        label="Remarks"
        type="remarks"
        fullWidth

        multiline
        rows={4}
        value={state.remarks}
        onChange={(e) => setState({ ...state, remarks: e.target.value })}

      />
      
      <FormControl margin="dense" sx={{ pl: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Over all Response.</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={state.conclusion}
          onChange={(e) => { setState({ ...state, conclusion: e.target.value }) }}
        >
          {['positive', 'negative', 'nutral'].map((item) => <FormControlLabel key={item} value={item} control={<Radio />} label={item.toLocaleUpperCase()} />)}



        </RadioGroup>
      </FormControl>


    </DialogContent>
    <DialogActions>
      <Button variant='contained' color='error' onClick={handleClose}>Cancle</Button>
      <Button type='submit' variant='contained' onClick={formSubmit} disabled={loading}>{loading ? <CircularProgress size={'25px'} /> : ' submit'}</Button>
    </DialogActions>

  </>
}
export default UpdateCaseModal