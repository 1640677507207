import { Edit, Preview } from "@mui/icons-material"
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material"

const TableRow = ({ caseId, name, item}) => {
    return (
        <Box sx={{ width: "100%", background: "white", borderRadius: 2 }} p={3} mt={3} >

         
            <Typography variant="subtitle1">Email: {item.email} </Typography>
            <Typography variant="subtitle1">Name: {item.name} </Typography>
            <Typography variant="subtitle1">role: {item.role} </Typography>
            <Typography variant="subtitle1">prefix: {item.prefix} </Typography>


        </Box>
    )
}
export default TableRow