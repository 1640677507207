const mode = 'production';
let domain = "";

switch (mode) {
  case "local":
    domain = "http://localhost:8000/";
    break;

  case 'production':
    domain = "https://patient-management-backend-production.up.railway.app/";
    break;

  default:
    domain = "http://localhost:8000/";
}

export default {
  signIn: `${domain}api/v1/auth/sign-in`,
  resetToken: `${domain}api/v1/auth/reset-token`,
  cases: `${domain}api/v1/case`,

  user: `${domain}api/v1/user`,
};
