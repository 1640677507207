import { useContext } from "react"
import { resetTokenApi } from "../api/auth.api"
import UserContext from "../contexts/UserContext"
import { accessToken, refreshToken } from "../utils/helper"

const useApi = () => {
    const user = useContext(UserContext)
    const fun = async (apiCall = async () => { }, onSuccess = async () => { }, onError = async () => { }) => {


        try {

            const response = await apiCall()
            if (response.status == 1) {
                await onSuccess(response.data)
            } else {
                if (response.code === 400) {
                    onError(Array.isArray(response.data) && response.data[0].msg)
                } else if (response.code === 401) {
                    const resetFunResponse = await resetTokenApi()
                    if (resetFunResponse.status === 1) {

                        accessToken.set(resetFunResponse.data.accessToken)
                        refreshToken.set(resetFunResponse.data.refreshToken)

                        return fun(apiCall, onSuccess, onError)

                    } else {
                        user.logOut()
                    }
                } else {
                    alert('something went wrong')
                    onError(response.message || "OOPS! Something went wrong")
                }
            }
            return
        } catch (e) {

            alert("something went wrong"+e.message)
            return
        }

    }
    return fun

}
export default useApi