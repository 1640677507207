import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Button, Chip, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { dateConverter } from '../../utils/helper';


const DataViewModal = ({ open, data, handleClose }) => {

  const findColorOfHearingLoss = (val) => {
    switch (val) {
      case "mild": return 'success'
      case "moderate": return 'primary'
      case "severe": return 'warning'
      case "profound": return 'error'
    }
  }
  
  const findColorOfConclusion = (val) => {
    switch (val) {
      case "positive": return 'success'
      case "nutral": return 'primary'
      case "negative": return 'warning'      
    }
  }
  if (!open) {
    return <></>
  }




  return <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>

    <DialogTitle>
      Patient Data

    </DialogTitle>

    <DialogContent>

      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Case Id: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}>{data.caseId}</Typography>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Name: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}>{data.name}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Phone No: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}>{data.phone}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Email: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}>{data.email}</Typography>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Age: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}>{data.age}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Gender: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}>{data.gender.toUpperCase()}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Aadhar Card No: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}>{data.adhar}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Address: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}>{data.address}</Typography>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Type of hearing Loss: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}><Chip p={0} label={data.typeOfLoss.toUpperCase()} /></Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Level of Hearing Loss: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}><Chip color={findColorOfHearingLoss(data.levelOfLoss)} label={data.levelOfLoss.toUpperCase()} /></Typography>
        </Grid>


        {data.audioGram && <Grid item sm={6} xs={12}>
          <Typography display="inline" variant='subtitle1' fontWeight="bold">Audiogram: </Typography>
          <Typography display="inline" variant='subtitle1' ml={1}><a target={"_blank"} href={data.audioGram} >Open File</a></Typography>
        </Grid>}






      </Grid>
      {data.profile && Array.isArray(data.profile) && data.profile.length > 0 && <>



        <Typography variant='h6' color={'grey'} mt={2} mb={2} >Patient Profile</Typography>


        {
          data.profile.map((item, index) => (
            <Box mb={2} sx={{ background: "whitesmoke" }} key={item._id} p={2}>
              <Typography variant='body1' color={'grey'} fontWeight="bold" mt={1} mb={1} >Trial {index + 1} ({dateConverter({ type: "DD_MM_YYYY", value:item.date})})</Typography>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                  <Typography variant='subtitle1' fontWeight="600"  sx={{color:"	#6082B6"}} mb={2}>Left Ear</Typography>


                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">500 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.l500}dB</Typography>
                  </Box>
                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">1000 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.l1k}dB</Typography>
                  </Box>
                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">2000 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.l2k}dB</Typography>
                  </Box>
                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">4000 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.l4k}dB</Typography>
                  </Box>
                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">8000 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.l8k}dB</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant='subtitle1' fontWeight="600"  sx={{color:"	#6082B6"}} mb={2}>Right Ear</Typography>

                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">500 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.r500}dB</Typography>
                  </Box>
                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">1000 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.r1k}dB</Typography>
                  </Box>
                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">2000 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.r2k}dB</Typography>
                  </Box>
                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">4000 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.r4k}dB</Typography>
                  </Box>
                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">8000 Hz: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.r8k}dB</Typography>
                  </Box>
                </Grid>

              </Grid>
               <Grid container spacing={2} >

                <Grid item xs={12} sm={6} >
                  <Typography variant='subtitle1' fontWeight="600" sx={{color:"	#6082B6"}} mb={2} mt={2}>Conclusion</Typography>


                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">Able to understand speech?</Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.ableToUnderstand?"Yes":"No"}</Typography>
                  </Box>
                  <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">Able to hear sound?</Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.ableToHear?"Yes":"No"}</Typography>
                  </Box>
                 {item.remarks && <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">Remarks:</Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}>{item.remarks}</Typography>
                  </Box>}

                  {item.conclusion && <Box mt={1} mb={1}>
                    <Typography display="inline" variant='subtitle1' fontWeight="bold">Overall Response: </Typography>
                    <Typography display="inline" variant='subtitle1' ml={1}><Chip color={findColorOfConclusion(item.conclusion)} label={item.conclusion.toUpperCase()} /></Typography>
                  </Box>}
               
                </Grid>
              

              </Grid>

            </Box >
          ))
        }
      </>}
    </DialogContent>
    <DialogActions>
      <Button variant='contained' onClick={handleClose}>Close</Button>
    </DialogActions>


  </Dialog>
}
export default DataViewModal