import { Box, Button, CircularProgress, TablePagination, TextField, Typography } from "@mui/material"
import { Container } from "@mui/system"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { fetchCases } from "../../../api/casesApi"
import { fetchUser } from "../../../api/user.api"
import useApi from "../../../hooks/useApi"
import Header from "../../subcomponents/Header"
import Searchbar from "../../subcomponents/SearchBar"
import TableRow from "./TableRows"
import UserModal from "./UserModal"
// import CaseModal from "./CaseModal"
// import DataViewModal from "./DataViewModal"

const UserPage = () => {

    const [model, setModel] = useState({ open: false, data: {}, step: 0 })


    const [loading, setLoading] = useState(false)
    const callApi = useApi()
    const [params, setParams] = useState({
        pageNo: 1,
        pageSize: 15,
        search: ''

    })
    const [data, setData] = useState({})

    const fetchData = async () => {
        setLoading(true)
        await callApi(async () => { return await fetchUser(params) }, (response) => { setLoading(false); setData(response) }, (err) => { setLoading(false); alert(err) })
    }


    const openModel = (data = {}) => {
        
        setModel({...model, open: true, data })
    }
    const closeModel = () => {
        setModel({...model, open: false, data: {}})
    }


    // const openViewModel = (data = {}, step = 1) => {
        
    //     setDataviewModel({...model, open: true, data, step })
    // }
    // const closeViewModel = () => {
    //     setDataviewModel({...model, open: false, data: {}, step: 0 })
    // }
    

    useEffect(() => {
        fetchData()
    }, [params])


    return (
        <>
            <Header />                    
            <UserModal key={model.open} open={model.open} data={model.data} initalStep={model.step} onComplete={async () => fetchData()} handleClose={closeModel} />
            <Container maxWidth="lg">
                <Box mt={4} mb={3} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Link  style={{textDecoration:"unset"}} to={"/"}><Button variant="contained" sx={{mr:2}} onClick={openModel}>Manage Cases</Button></Link>
                    <Button variant="contained" onClick={openModel}>Create User</Button>
                </Box>
                <Searchbar onChange={(e) => { setParams({ ...params, search: e.target.value }) }} fullWidth={true} placeholder="Search User by Name" />
                {loading && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} mt={3}>
                    <CircularProgress />
                </Box>}
                {!loading && data && data.data && Array.isArray(data.data) && <>
                    <TablePagination
                        component="div"
                        count={data.count ?? 0}
                        page={params.pageNo-1}
                        rowsPerPageOptions={[15]}
                        onPageChange={(e, newPage) => { setParams({ ...params, pageNo: newPage }) }}
                        rowsPerPage={15}
                        onRowsPerPageChange={() => { }}
                    />
                    {
                        data.data.length == 0 && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} mt={3}>
                            <Typography variant="h5" >No data found</Typography>
                        </Box>
                    }
                    {
                        data.data.map((item) => <TableRow key={item.caseId} item={item} />)
                    }

                </>}


            </Container>
        </>
    )

}
export default UserPage