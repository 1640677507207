import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel,  Radio, RadioGroup,  Typography } from '@mui/material';
import useApi from '../../../hooks/useApi';
import { createCases } from '../../../api/casesApi';

const CreateCaseModal = ({ onSubmit, handleClose }) => {
  const [loading, setLoadng] = React.useState(false)
  const callApi = useApi()
  const [state, setState] = React.useState({
    name: '',
    email: '',
    age: '',
    gender: 'male',
    phone: '',
    err: '',
    typeOfLoss: 'conductive',
    adhar: '',
    address: '',
    levelOfLoss: 'mild',
    isAvialbleAudioGram: false,
    audioGram: null,
  })
  const formSubmit = async () => {
    setLoadng(true)
    const formData = new FormData()
    Object.keys(state).forEach((item) => {
      if (state[item] && state[item] != '')
        formData.append(item, state[item])
    })
    callApi(async () => createCases(formData), (response) => { setLoadng(false); onSubmit(response._id) }, (errMsg) => {
      setLoadng(false);
      setState({ ...state, err: errMsg })
    })
  }
  return <>
    <DialogTitle>
      Register Patient
      <Typography color={'error'}>{state.err}</Typography>
    </DialogTitle>

    <DialogContent>

      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Name *"
        type="name"
        fullWidth
        value={state.name}
        onChange={(e) => setState({ ...state, name: e.target.value })}

      />
      <TextField
        autoFocus
        margin="dense"
        id="phone"
        label="Phone No. *"
        type="phone"
        fullWidth
        value={state.phone}
        onChange={(e) => setState({ ...state, phone: e.target.value })}

      />
      <TextField
        autoFocus
        margin="dense"
        id="emial"
        label="Email Address"
        type="email"
        fullWidth
        value={state.email}
        onChange={(e) => setState({ ...state, email: e.target.value })}

      />
      <TextField
        autoFocus
        margin="dense"
        id="age"
        label="Age *"
        type="age"
        fullWidth
        value={state.age}
        onChange={(e) => setState({ ...state, age: e.target.value })}

      />
      <FormControl margin="dense" sx={{ pl: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={state.gender}
          onChange={(e) => { setState({ ...state, gender: e.target.value }) }}
        >
          <FormControlLabel value="male" control={<Radio />} label="Male" />
          <FormControlLabel value="female" control={<Radio />} label="Female" />
          <FormControlLabel value="other" control={<Radio />} label="Other" />

        </RadioGroup>
      </FormControl>

      <TextField
        autoFocus
        margin="dense"
        id="adhar"
        label="Aadhar card No."
        type="adhar"
        fullWidth
        value={state.adhar}
        onChange={(e) => setState({ ...state, adhar: e.target.value })}

      />
      <TextField
        autoFocus
        margin="dense"
        id="address"
        label="Address"
        type="address"
        fullWidth
        multiline
        rows={3}
        value={state.address}
        onChange={(e) => setState({ ...state, address: e.target.value })}

      />
      <FormControl margin="dense" sx={{ pl: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Type of hearing Loss</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={state.typeOfLoss}
          onChange={(e) => { setState({ ...state, typeOfLoss: e.target.value }) }}
        >
          {['conductive', 'sn', 'mixed'].map((item) => <FormControlLabel key={item} value={item} control={<Radio />} label={item.toLocaleUpperCase()} />)}



        </RadioGroup>
      </FormControl>
      <Box>
        <FormControl margin="dense" sx={{ pl: 1 }}>
          <FormLabel id="demo-controlled-radio-buttons-group">Level of hearing loss</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={state.levelOfLoss}
            onChange={(e) => { setState({ ...state, levelOfLoss: e.target.value }) }}
          >
            {['mild', 'moderate', 'severe', 'profound'].map((item) => <FormControlLabel key={item} value={item} control={<Radio />} label={item.toLocaleUpperCase()} />)}

          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <FormControl margin="dense" sx={{ pl: 1 }}>
          <FormLabel id="demo-controlled-radio-buttons-group">Is audiogram available?</FormLabel>
          {/* <Switch checked={state.isAvialbleAudioGram} onChange={(e) => setState({ ...state, isAvialbleAudioGram: e.target.checked })} /> */}
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={state.isAvialbleAudioGram}
            onChange={(e) => { setState({ ...state, isAvialbleAudioGram: (e.target.value) }) }}
          >

            <FormControlLabel value={true} control={<Radio />} label={"Yes"} />

            <FormControlLabel value={false} control={<Radio />} label="No" />

          </RadioGroup>
        </FormControl>
      </Box>      
      {state.isAvialbleAudioGram == 'true' &&
        <Box >
          <FormControl margin="dense" sx={{ pl: 1 }}>
            <FormLabel id="demo-controlled-radio-buttons-group" sx={{ mb: 1 }}>Upload audiogram File:</FormLabel>
            <input type="file" accept=".pdf,.png,.jpeg,.jpg" onChange={(e) => setState({ ...state, audioGram: e.target.files[0] })} />
          </FormControl>

        </Box>
      }

    </DialogContent>
    <DialogActions>
      <Button variant='contained' color='error' onClick={handleClose}>Cancle</Button>
      <Button type='submit' variant='contained' onClick={formSubmit} disabled={loading} >{loading ? <CircularProgress size={'25px'} /> : ' Next'}</Button>
    </DialogActions>

  </>
}
export default CreateCaseModal