import { Box, Button, Paper, Typography } from "@mui/material"
import { useContext } from "react"
import UserContext from "../../contexts/UserContext"

const Header = () => {
    const user = useContext(UserContext)
    return <Paper sx={{ width: "100%", position: "sticky", top: "0px", p: 3,zIndex:11 ,display:"flex",alignItems:"center"}}>
        <Box >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Box>
        <Box sx={{ display:"flex",flexDirection:"column",alignItems:"center" ,flex:1}}>
            <Typography variant="h5"  align="center">Patient Data Management System</Typography>
            <Typography variant="subtitle1"  align="center">By WeHear</Typography>
        </Box>
        <Button variant="contained" onClick={()=>{user.logOut()}} color="error" >Log Out</Button>
    </Paper>
}
export default Header