

export const accessToken = {
  set: val => {
    localStorage.setItem("patient_manage_access_token", val);
  },
  get: () => localStorage.getItem("patient_manage_access_token"),
  remove: () => {
    localStorage.removeItem("patient_manage_access_token");
  },
};
export const loggedInUser = {
  set: val => {
    localStorage.setItem("patient_manage_user", JSON.stringify(val));

  },
  get: () => localStorage.getItem("patient_manage_user") ? JSON.parse(localStorage.getItem("patient_manage_user")) : null,
  remove: () => {
    localStorage.removeItem("patient_manage_user");
  },
};

export const refreshToken = {
  set: val => {
    localStorage.setItem("patient_manage_refresh_token", val);
  },
  get: () => localStorage.getItem("patient_manage_refresh_token"),
  remove: () => {
    localStorage.removeItem("patient_manage_refresh_token");
  },
};
export const logOut = () => {
  refreshToken.remove();
  accessToken.remove();
  loggedInUser.remove()

};


export const getHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;
  return {
    Authorization: token,
  };
};
export function setZeroPrefix(val) {
  if (parseInt(val, 10) < 10) {
    return `0${val}`;
  }
  return val;
}

export const dateConverter = ({ type = "DD_MM_YYYY", value }) => {
  if (type === "DD_MM_YYYY") {
    const dateObj = new Date(value);
    return `${setZeroPrefix(dateObj.getDate())}/${setZeroPrefix(
      dateObj.getMonth() + 1
    )}/${dateObj.getFullYear()}`;
  }
  return value;
};

export const validateEmail = (email) => {
  var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
}
export const validatePhone = (phone) => {
  var re = /^\d{10}$/;
  return phone.match(re);
}
