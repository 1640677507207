import { useCallback, useState } from "react"
import { signInApi } from "../api/auth.api"
import UserContext from "./UserContext"

const UserContextProvider = ({ children }) => {
    const [state, setState] = useState({ isLoggedIn: false, user: null })

    const logOut = useCallback(() => {
        setState({ isLoggedIn: false, user: null })
    }, [])
    const logIn = useCallback(async (user) => {
        setState({ isLoggedIn: true, user })        
    }, [setState])
    return <UserContext.Provider value={{ ...state, logOut, logIn }}>{children}</UserContext.Provider>
}

export default UserContextProvider