import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { signInApi } from '../../api/auth.api';
import { accessToken, refreshToken } from '../../utils/helper';
import UserContext from '../../contexts/UserContext';
import { CircularProgress } from '@mui/material';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://wehear.in/">
                Wehear
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



export default function Login() {
    const [loading, setLoading] = React.useState(false)
    const [err, setErr] = React.useState(false)
    const user = React.useContext(UserContext)
    const handleSubmit = async (event) => {

        event.preventDefault();
        setLoading(true)
        const data = new FormData(event.currentTarget);

        const response = await signInApi({
            email: data.get('email'),
            password: data.get('password'),
        })

        if (response.status == 1) {
            accessToken.set(response.data.accessToken)
            refreshToken.set(response.data.refreshToken)
            user.logIn(response.data)
        } else {
            if (response.code == 400) {
                setErr(response.data[0].msg)
            } else {
                setErr(response.message)
            }
        }
        setLoading(false)

    };

    return (

        <Container maxWidth="xs">

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    p: 5,
                    alignItems: 'center',
                    border: "2px solid black", borderRadius: 3
                }}
            >

                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Typography color="red" variant="subtitle2">
                        {err}
                    </Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        disabled={loading}
                        variant={loading ? "outlined" : "contained"}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {loading ? <CircularProgress color="info" /> : 'Sign In'}
                    </Button>

                </Box>
            </Box>
            <Copyright sx={{ mt: 1, mb: 4 }} />
        </Container>

    );
}