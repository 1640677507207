import { Edit, Preview } from "@mui/icons-material"
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material"

const TableRow = ({ caseId, name, item, onEditButtonClick ,onViewButtonClick}) => {
    return (
        <Box sx={{ width: "100%", background: "white", borderRadius: 2 }} p={3} mt={3} >

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography variant="subtitle1">
                    <Tooltip title="view all data">
                        <IconButton onClick={() => { onViewButtonClick(item) }}>
                            <Preview fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit  data">
                        <IconButton onClick={() => { onEditButtonClick({ updateId: item._id }, 2) }} >
                            <Edit fontSize="inherit" />
                        </IconButton>
                    </Tooltip>

                </Typography>
            </Box>
            <Typography variant="subtitle1">Case Id : {item.caseId} </Typography>
            <Typography variant="subtitle1">Patient Name: {item.name} </Typography>


        </Box>
    )
}
export default TableRow