import axios from "axios";
import { getHeaders, refreshToken } from "../utils/helper";

import endpoints from "./endpoints";

export const createUser = async data => {
    const callResponse = await axios({
        url: endpoints.user,
        method: "post",
        headers:{...getHeaders()},
        data,
    })
        .then(response => response.data)
        .catch(err => (err.response ? err.response.data : {}));
    return callResponse;
};
export const updateUser = async data => {
    const callResponse = await axios({
        url: endpoints.user,
        method: "put",        
        headers:{...getHeaders()},
        data,
    })
        .then(response => response.data)
        .catch(err => (err.response ? err.response.data : {}));
    return callResponse;
};
export const fetchUser = async params => {
    const callResponse = await axios({
        url: endpoints.user,
        method: "get",  
        headers:{...getHeaders()},      
        params,
    })
        .then(response => response.data)
        .catch(err => (err.response ? err.response.data : {}));
    return callResponse;
};